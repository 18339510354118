import React from 'react'
import '../styles/index.css'

//Components 
import Header from '../components/Header'

function OmOs() {
  return (
    <>
      <Header />
      <div>OmOs</div>
    </>
  )
}

export default OmOs